.additional-products-page__margin-items > :not(:first-child) {
  margin-left: 12px !important;
}

.additional-products-page__btn {
  align-items: center;
  border-radius: 4px;
  border: 1px solid #337ab7;
  display: flex;
  height: fit-content;
  padding: 4px 12px;
}

.additional-products-page__btn:disabled {
  opacity: 0.5;
}

.additional-products-page__primary-btn {
  background-color: #337ab7;
  color: #ffffff;
}

.additional-products-page__secondary-btn {
  background-color: #ffffff;
  color: #337ab7;
}

.additional-products-page__btn-icon {
  height: 16px !important;
  margin-left: 4px;
  width: 16px !important;
}

.additional-products-page__card-header {
  align-items: center;
  display: flex;
}

.additional-products-page__card-header-icon {
  cursor: pointer;
  margin-right: 5px;
  padding: 5px;
}

.additional-products-page__card-header-title {
  margin: 0px !important;
}

.additional-products-page__header-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.additional-products-page__header-col {
  align-items: center;
  display: flex;
}

.additional-products-page__search-btn-wrapper {
  display: contents;
}

.additional-products-page__search-btn {
  background-color: #337ab7;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0px;
  padding: 12px;
}

.additional-products-page__note-text {
  align-self: baseline;
}

.additional-products-page__selector {
  width: 200px;
}

.additional-products-page__empty-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
}

.additional-products-page__empty-img {
  height: 160px !important;
}

.additional-products-page__empty-text {
  color: #bbbfcc;
}

.additional-products-page__modal-header {
  background-color: #f3873a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 12px !important;
  padding-top: 12px !important;
}

.additional-products-page__modal-title {
  color: white;
}

.additional-products-page__upload-label {
  align-items: center;
  background: #f6f7f9;
  border-radius: 4px;
  border: 1px dashed #bbbfcc;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  height: 160px;
  justify-content: center;
  width: 100%;
}

.additional-products-page__upload-icon {
  margin-bottom: 4px;
}

.additional-products-page__upload-subtle {
  color: #838799;
  font-size: 16px;
  font-weight: 400;
}

#additional-products-page__upload-input {
  display: none;
}

.additional-products-page__modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.additional-products-page__file-list {
  margin-top: 15px;
}

.additional-products-page__file-container {
  margin-top: 8px;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #dcdee6;
  display: flex;
  padding: 5px 8px;
}

.additional-products-page__file-icon {
  color: green;
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.additional-products-page__alert-container {
  background-color: #ffe5e5 !important;
  border-radius: 2px;
  border: 0.5px solid #d91616;
  color: black !important;
  display: flex;
  padding: 10px 12px;
}

.additional-products-page__alert-icon {
  color: #d91616;
  margin-right: 4px;
}

.additional-products-page__alert-link {
  color: #337ab7 !important;
  text-decoration: underline;
}

.additional-products-page__error-tag {
  background-color: #f6f7f9;
  border-radius: 32px;
  padding: 4px 8px;
}
