.pad-btn {
  width: 80px;
  float: left;
  margin: 0 5px 10px 5px;
}

.phone {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #fff;
}

.phone-title {
  font-size: 20px;
  font-weight: bold;
}

.num-display {
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  border: 1px solid #9e9e9e;
  border-radius: 99px;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.num-btn {
  border: 1px solid #9e9e9e;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  height: 80px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
}

.num-btn:hover {
  background-color: #E5E7E9;
  transition-property: background-color 0.2s linear 0s;
  -moz-transition: background-color 0.2s linear 0s;
  -webkit-transition: background-color 0.2s linear 0s;
  -o-transition: background-color 0.2s linear 0s;
}

.num-txt {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-top: 25px;
  font-family: 'Lato', sans-serif;
  line-height: 30px;
  color: #333;
}

.func-btn {
  border: 1px solid #9e9e9e;
  -webkit-border-radius: 999px;
  border-radius: 999px;
  -moz-border-radius: 999px;
  height: 80px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  padding-top: 27px;
}

.func-btn:hover {
  background-color: #E5E7E9;
  transition-property: background-color 0.2s linear 0s;
  -moz-transition: background-color 0.2s linear 0s;
  -webkit-transition: background-color 0.2s linear 0s;
  -o-transition: background-color 0.2s linear 0s;
}

.action-btn {
  font-size: 24px;
  float: left;
  border-radius: 99px;
}

.new-register-btn {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  min-width: fit-content;
}

.new-register-btn:hover {
  background-color: #236daf;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

.num-display::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: rgb(211, 111, 111);
  font-weight: normal;
}

/* floats responsive layouts */
@media screen and (max-width: 320px) {
  .pad-btn {
    width: 29%;
  }
}

@media screen and (min-width: 360px) {
  .pad-btn {
    width: 30%;
  }
}

@media screen and (min-width: 658px) {
  .pad-btn {
    width: 31%;
  }
}

@media screen and (min-width: 1024px) {
  .pad-btn {
    width: 80px;
    margin: 0 5px 10px 5px;
  }
}