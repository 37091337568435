
.set-banner .select-banner.Select--multi .Select-value.banner-expired {
    background-color: rgba(130, 134, 154, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(130, 134, 154, 0.24);
    color: #82869A !important;
}
.set-banner .select-banner.Select--multi .Select-value.banner-active {
    background-color: rgba(76, 175, 80, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(76, 175, 80, 0.24);
    color: #4caf50 !important;
}


.set-banner .select-banner.Select--multi .Select-value.banner-expired .Select-value-icon:hover, 
.set-banner .select-banner.Select--multi .Select-value.banner-expired .Select-value-icon:focus {
    background-color: rgba(130, 134, 154, 0.1);
    color: #82869A !important;
}

.set-banner .select-banner.Select--multi .Select-value.banner-active .Select-value-icon:hover, 
.set-banner .select-banner.Select--multi .Select-value.banner-active .Select-value-icon:focus {
    background-color: rgba(76, 175, 80, 0.1);
    color: #4caf50 !important;
}


.set-banner .select-banner.Select--multi .Select-option.banner-expired {
    color: #82869A !important;
}

.set-banner .select-banner.Select--multi .Select-option.banner-active {
    color: #4caf50 !important;
}

.set-banner .select-banner.Select--multi .Select-option.banner-expired:hover,
.set-banner .select-banner.Select--multi .Select-option.banner-expired.is-focused {
    background-color: rgba(130, 134, 154, 0.1);
}

.set-banner .select-banner.Select--multi .Select-option.banner-active:hover,
.set-banner .select-banner.Select--multi .Select-option.banner-active:active {
    background-color: rgba(76, 175, 80, 0.06);
}

.conditin-text-set-banner-green {
    color: #4caf50 !important;
    background-color: rgba(76, 175, 80, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(76, 175, 80, 0.24);
    padding: 0px 1rem
}
.conditin-text-set-banner-red {
    color: #82869A !important;
    background-color: rgba(130, 134, 154, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(130, 134, 154, 0.24);
    padding: 0px 1rem;
}

.pl-1{
    padding-left: 1rem;
}

.Select-option.heroBannerUrl-inactive {
    display: none;
}

.set-banner .detail-ready-to-use {
    display: flex;
    gap: 10px;
    font-size: 17px;
}

.set-banner .detail-ready-to-use span:nth-child(1) {
    color: #4CAF50;
}

.set-banner .detail-ready-to-use span:nth-child(2) {
    color: #82869A;
}

.set-banner .detail-ready-to-use span:before {
    content:"• ";
}