.device-container {
  zoom: 0.85;
}

@media (max-width: 1600px) {
  .device-container {
    zoom: 0.6;
  }
}

.device-column {
  display: flex;
  justify-content: center;
}

.device-background {
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
}

.notification-container {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 6px;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 17.5%;
  bottom: 45%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(21.4054px);
  line-height: 1.1;
  height: max-content;
  min-height: 79px;
  font-size: 18px;
  background-color: #eef3fc;
}

.icon-container {
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
}

.iphone-badge-icon {
  height: 45px !important;
  width: 45px !important;
  border-radius: 6px;
}

.notification-dot {
  border-radius: 36px;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 8px;
  top: 12px;
  background: #f15d42;
  z-index: 2;
}

.content-container {
  width: 100%;
}

.content-row {
  display: flex;
  justify-content: space-between;
}

.title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: bold;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}

.time {
  color: #838799;
  display: flex;
  justify-content: center;
  align-items: end;
}

.message {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  font-weight: lighter;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  word-break: break-word;
}

.iphone-notification {
  left: 5%;
  right: 5%;
  top: 45%;
  bottom: 45%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(21px);
  border-radius: 12px;
  color: white;
  min-height: 88px;
}

.iphone-time {
  display: flex;
  justify-content: space-around;
  align-items: end;
  padding-right: 12px;
}

.iphone-message {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
