.checkbox-container {
  width: 100%;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.checkbox-container input[type='checkbox']:disabled {
  cursor: not-allowed !important;
}

.checkbox-container input[type='checkbox'] {
  width: 20px;
  height: 20px;
  max-width: 10%;
  border: 2px solid #f3873a; /* Border color */
  border-radius: 4px; /* Optional: to make corners rounded */
  position: relative;
  cursor: pointer;
  outline: none;
  appearance: none; /* Remove default checkbox styling */
  margin-bottom: 5px;
}

.checkbox-container input[type='checkbox']:checked {
  background-color: #f3873a; /* Background color when checked */
}

.checkbox-container input[type='checkbox']:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px; /* Size of the check mark */
  height: 6px;
  border: solid white; /* White check mark color */
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -70%) rotate(-45deg) scaleX(-1);
}

.checkbox-container input[type='checkbox']:indeterminate {
  background-color: #f3873a;
}

.checkbox-container input[type='checkbox']:indeterminate::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 2px;
  background-color: white;
  transform: translate(-50%, -50%);
}

.checkbox-container label {
  max-width: 100%;
  margin-bottom: 0;
}

.checkbox-container span {
  max-width: 100%;
  margin-left: 8px;
}

.checkbox-container .counter {
  max-width: 10%;
  margin-left: 5px;
  font-weight: 300;
  color: #98a1b6;
}

.checkbox-container .expand-icon {
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .checkbox-container {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
  }

  .checkbox-container input[type='checkbox'] {
    max-width: 100%;
  }

  .checkbox-container label {
    text-align: start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    max-width: 80%;
  }

  .checkbox-container span {
    text-align: start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    max-width: 80%;
  }

  .checkbox-container .expand-icon {
    margin-top: 5px;
  }
}

@media screen and (max-width: 576px) {
  .checkbox-container {
    width: 100%;
    align-items: flex-start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
  }

  .checkbox-container input[type='checkbox'] {
    max-width: 100%;
  }

  .checkbox-container label {
    text-align: start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    max-width: 60%;
  }

  .checkbox-container span {
    text-align: start;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    max-width: 60%;
  }

  .checkbox-container .expand-icon {
    margin-top: 5px;
  }
}
