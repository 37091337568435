.selling-point .Select-option {
  z-index: 100;
}

.selling-point img {
  width: 25px !important;
}

.selling-point-button {
  text-align: right;
}

.information-button-desktop {
  display: block;
  text-align: right;
}

.information-button-mobile {
  display: none;
  text-align: right;
}

.ad-banner-img {
  /* max-width: 250px !important; */
  height: auto !important;
}

.ad-banner-delete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: #ff0000;
  cursor: pointer;
  padding: 2px 4px;
  line-height: 0.5em;
  border-radius: 50%;
}

.ad-banner-delete:hover {
  box-shadow: 11 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}

.ad-banner-delete:hover i {
  color: #dddddd;
}

.ad-banner-delete i {
  color: #ffffff;
}

.no-ad-banner-image {
  margin: 10px 0;
  padding: 8px 20px;
}

@media screen and (max-width: 480px) {
  .information-button-desktop {
    display: none;
  }

  .information-button-mobile {
    display: block;
  }
}