.coupon-container {
    padding-bottom: 20px;
}   

.coupon-button-box {
    margin-top: 20px;
}

.coupon-container .Select-menu{
    height: 150px;
}

.p4-select.error-select-branch .Select-control {
    border: 1px solid #e53935 !important;
    background-color: rgba(236, 88, 64, 0.1) !important;
}

.coupon-search-select.Select {
    width: 100%;
    height: 40px;
    padding-top: 0px;
}

.coupon-group {

}

.coupon-group .btn.add-by-channel,
.coupon-group .btn.del-by-channel {
    font-size: initial;
    margin-top: 6px;
    font-size: 18px;
    line-height: 21px;
    border-radius: 3px !important;
    border: 1px solid #ccc;
    box-shadow: none;
}

.coupon-group .btn.add-by-channel .fa ,
.coupon-group .btn.del-by-channel .fa { 
    font-size: 14px;
    vertical-align: text-top;
    line-height: 22px;
}
.coupon-group .btn.add-by-channel .fa {
    /* padding-right: 10px; */
    /* color: #4caf50; */
}
.coupon-group .btn.del-by-channel .fa {
    /* padding-right: 10px; */
    /* color: #f44336; */
}
.coupon-group .btn.add-by-channel {
    margin-left: 10px;
    margin-right: 10px;
}

.coupon-group .coupons-select.Select--multi .Select-value.installation-class {
    background-color: rgba(255, 139, 0, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(255, 139, 0, 0.24);
    color: #f65d12;
}
.coupon-group .coupons-select.Select--multi .Select-value.survey-class {
    background-color: rgba(0, 184, 217, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(0, 184, 217, 0.24);
    color: #1a8ef2;
}


.coupon-group .coupons-select.Select--multi .Select-value.installation-class .Select-value-icon:hover, 
.coupon-group .coupons-select.Select--multi .Select-value.installation-class .Select-value-icon:focus {
    background-color: rgba(255, 139, 0, 0.1);
    color: #f65d12;
}

.coupon-group .coupons-select.Select--multi .Select-value.survey-class .Select-value-icon:hover, 
.coupon-group .coupons-select.Select--multi .Select-value.survey-class .Select-value-icon:focus {
    background-color: rgba(0, 184, 217, 0.1);
    color: #1a8ef2;
}


.coupon-group .coupons-select.Select--multi .Select-option.installation-class {
    color: #f65d12;
}

.coupon-group .coupons-select.Select--multi .Select-option.survey-class {
    color: #1a8ef2;
}

.coupon-group .coupons-select.Select--multi .Select-option.installation-class:hover,
.coupon-group .coupons-select.Select--multi .Select-option.installation-class.is-focused {
    background-color: rgba(255, 139, 0, 0.06);
}

.coupon-group .coupons-select.Select--multi .Select-option.survey-class:hover,
.coupon-group .coupons-select.Select--multi .Select-option.survey-class:active {
    background-color: rgba(0, 184, 217, 0.06);
}

.coupon-group .new-codes.installation-tags .react-tagsinput-tag {
    background-color: rgba(255, 139, 0, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(255, 139, 0, 0.24);
    color: #f65d12;
}

.coupon-group .new-codes.survey-tags .react-tagsinput-tag {
    background-color: rgba(0, 184, 217, 0.1);
    border-radius: 2px;
    border: 1px solid rgba(0, 184, 217, 0.24);
    color: #1a8ef2;
}

.coupon-group .new-codes.installation-tags .react-tagsinput-tag a.react-tagsinput-remove {
    color: #f65d12;
}

.coupon-group .new-codes.survey-tags .react-tagsinput-tag a.react-tagsinput-remove {
    color: #1a8ef2;
}

.coupon-group .remark-coupon {
}

.coupon-group .remark-coupon ul {
    margin-top: 20px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.coupon-group .remark-coupon ul li {
    display: inline;
    margin-right: 15px;
    font-size: 16px;
}

.coupon-group .remark-coupon ul li i {
    font-size: 10px;
    margin-right: 5px;
}

.coupon-group .remark-coupon ul li.survey-rc i {
    color: #1a8ef2;
}
.coupon-group .remark-coupon ul li.installation-rc i {
    color: #f65d12;
}

.s-checkbox {
    padding-top: 28px;
    /* padding-left: 20px; */
}

.coupon-page .react-tagsinput-input,
.coupon-group .react-tagsinput-input {
    width: 100%;
}

.coupon-page .switch-field,
.coupon-group .switch-field {
    font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
    padding: 40px;
      overflow: hidden;
}
  
.coupon-page .switch-title,
.coupon-group .switch-title {
    font-weight: bold;
    margin-bottom: 6px;
}
  
.coupon-page .switch-field input,
.coupon-group .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
}
  
.coupon-page .switch-field label,
.coupon-group .switch-field label {
    display: inline-block;
    background-color: #e4e4e4;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
}
  
.coupon-page .switch-field label:hover,
.coupon-group .switch-field label:hover {
    cursor: pointer;
}
  
.coupon-page .item-card .switch-field input:checked + label,
.coupon-group .item-card .switch-field input:checked + label {
    background-color: #3dbcd4;
    color: #fff;
    box-shadow: none;
}
  
.coupon-page .switch-field label:first-of-type,
.coupon-group .switch-field label:first-of-type {
    border-radius: 5px;
    margin-right: 10px;
}
  
.coupon-page .switch-field label:last-of-type,
.coupon-group .switch-field label:last-of-type {
    border-radius: 5px;
}

.coupon-page .checkbox-scale-12,
.coupon-group .checkbox-scale-12 {
    transform: scale(1.2);
}

.coupon-page input[type="checkbox"],
.coupon-group input[type="checkbox"] {
    filter: invert(0%) hue-rotate(230deg) brightness(1.0);
  }