.document-picker {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 4px 8px;
  border: 1px solid;
  border-color: rgba(37, 40, 43, 0.25);
  border-radius: 4px;
}

.document-picker > .picker-btn {
  display: flex;
  align-items: center;
  width: auto;
  height: calc(100% - 4px);
  padding: 0px 4px;
  margin-left: auto;
  font-size: 14px;
  line-height: 1em;
  white-space: nowrap;
  color: white;
  background-color: #297cbb;
  border-radius: 4px;
  cursor: pointer;
}

.picker-btn > i {
  transform: scale(0.8);
}

.document-picker > span {
  color: gray;
  line-height: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-picker > span.file-selected {
  color: #3fb3d9;
}
