ul.nav.nav-tabs.tabs-status {
  border-bottom: 1px solid #eee;
}

.alert-paid a.close{
  position: initial;
}

.alert-paid{
  box-shadow: none !important;
}

.nav-tabs.tabs-status>li>a {
  color: #b3b3b3 !important;
  font-size: 20px;
  cursor: pointer;
}

.nav-tabs.tabs-status>li.active>a {
  color: #000 !important;
  border: 1px solid #eee !important;
  border-bottom: 2px solid #e9443f !important;
}

ul.nav-tabs.tabs-status.tab-right {
  float: right;
  border-bottom: 0;
}

.tab-content {
  margin-top: 20px;
}

.job-info .title {
  font-size: 24px;
  text-align: center;
  font-weight: 700;
}

.success-page {
  text-align: center;
  color: '#0fad00';
}

.label-name {
  font-weight: 700;
  padding: 10px 15px 10px 0;
}

.nav-tabs {
  background-color: #fff;
}

.design-process-section .text-align-center {
  line-height: 25px;
  margin-bottom: 12px;
}

.design-process-content {
  border: 1px solid #e9e9e9 !important;
  padding: 10px;
  border-radius: 5px;
}

.design-process-content .title {
  font-weight: 700;
}

.design-process-content .title-status {
  font-weight: 700;
  text-align: center;
}

.design-process-content .content {
  font-size: 16px;
}

.design-process-content img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  max-height: 100%;
}

.design-process-content h3 {
  margin-bottom: 16px;
}

.design-process-content p {
  line-height: 26px;
  margin-bottom: 12px;
}

.process-model {
  list-style: none;
  padding: 0;
  position: relative;
  margin: 0;
  border: none;
  z-index: 0;
  min-width: 600px;
}

.status-progress {
  overflow-x: auto;
  padding-bottom: 10px;
}

.step-scroll {
  display: none;
}

.step-container {
  margin-bottom: 20px;
}

.step-container .list-group-item {
  padding: 2px 0 2px 0 !important;
  border-top: 0 !important;
}

@media screen and (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb:horizontal {
    background: #4B6A89;
    border: thin solid #4B6A89;
    border-radius: 10px;
    height: 1px;
    display: none;
  }
  ::-webkit-scrollbar-thumb:vertical {
    background: #A1A7AC;
    border: thin solid #dfdfdf;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #4B6A89;
  }
  .step-container {
    position: relative;
  }
  .step-container .step-scroll {
    z-index: 1;
  }
  .step-container .left-scroll {
    position: absolute;
    left: -20px;
    top: 23px;
    z-index: 1;
  }
  .step-container .right-scroll {
    position: absolute;
    right: -10px;
    top: 23px;
    z-index: 1;
  }
  .step-container .arrow {
    cursor: pointer;
    font-size: 20px;
    color: #606060;
  }
  .step-container .arrow:hover {
    color: #ada8a8;
  }
  .step-container .status-progress {
    margin-right: 10px;
  }
  .step-scroll {
    display: block !important;
  }
}

.process-model li::before {
  background: #e5e5e5 none repeat scroll 0 0 !important;
  bottom: 0;
  content: "";
  display: block;
  height: 4px;
  margin: 0 auto;
  position: absolute;
  right: 60%;
  top: 33px;
  width: 85%;
  z-index: -1;
}

.process-model li.visited::before {
  background: #f86901 !important;
}

.process-model li:first-child::before {
  width: 0;
}

.process-model li.active::before {
  background: #f86901 !important;
}

.process-model li.active i {
  border: 5px inset #f86901 !important;
  background: #fff !important;
}

.process-model li i {
  margin-left: 0;
}

.process-model li {
  display: inline-block;
  width: 25%;
  text-align: center;
}

.nav-tabs.process-model>li.active>a,
.nav-tabs.process-model>li.active>a:hover,
.nav-tabs.process-model>li.active>a:focus,
.process-model li a:hover,
.process-model li a:focus {
  border: none;
  background: transparent;
  color: #f86901 !important;
}

.nav-tabs.process-model>li.inactive {
  pointer-events: none;
}

.process-model li a {
  padding: 0;
  border: none;
  color: #606060 !important;
}

.process-model li.active,
.process-model li.visited {
  color: #f86901 !important;
}

.process-model li.active a,
.process-model li.active a:hover,
.process-model li.active a:focus,
.process-model li.visited a,
.process-model li.visited a:hover,
.process-model li.visited a:focus {
  color: #f86901 !important;
  cursor: pointer;
}

.process-model li.active p,
.process-model li.visited p {
  font-weight: 600;
}

.process-model li i {
  display: block;
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  background: #f5f6f7 !important;
  border: 2px solid #e5e5e5 !important;
  line-height: 65px;
  font-size: 30px;
  border-radius: 50%;
}

.process-model li.visited i {
  border-color: #f86901 !important;
}

.process-model li p {
  font-size: 14px;
  margin-top: 11px;
}

.process-model.contact-us-tab li.visited a,
.process-model.contact-us-tab li.visited p {
  color: #606060 !important;
  font-weight: normal
}

.process-model.contact-us-tab li::after {
  display: none;
}

.process-model.contact-us-tab li.visited i {
  border-color: #e5e5e5 !important;
}

@media screen and (max-width: 560px) {
  .more-icon-preocess.process-model li span {
    font-size: 23px;
    height: 50px;
    line-height: 46px;
    width: 50px;
  }
}

@media screen and (max-width: 380px) {
  .process-model.more-icon-preocess li {
    width: 16%;
  }
  .more-icon-preocess.process-model li span {
    font-size: 16px;
    height: 35px;
    line-height: 32px;
    width: 35px;
  }
  .more-icon-preocess.process-model li p {
    font-size: 12px;
  }
  .process-model.more-icon-preocess {
    text-align: center;
  }
}

.checkbox label {
  color: #000;
}

/* NPS Page */
.nps-page {
  text-align: center;
  color: '#0fad00';
}

.px-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.mx-0 {
  margin-right: 0px;
  margin-left: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.comment-box {
  background-color: #e5e5e5;
}

/* floats responsive layouts */
@media screen and (min-width:320px)  {
  /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
  .nps-page {
    padding-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .row.button {
    padding: 0px;
    display: inline-block;
    align-items: center;
    margin-left: auto;
  }
  .row.option-list {
    display: flex;
    align-items: center;
  }
  .comment {
    padding-left: 10px;
    padding-right: 10px;
  }
  .option-6 {
    margin-left: 25px;
  }
  .nps-btn .score {
    margin-left: 20px;
    /* height: 150px; */
  }
  .nps-btn p  {
    white-space: nowrap;
  }

}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  {
  /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
  .row.button {
    padding-top: 30px;
    padding-bottom: 20px;
  }
  .comment {
    padding-left: 50px;
    padding-right: 50px;
  }
  .option-6 {
    margin-left: 0px;
  }
  .nps-btn .btn .fa  {
    font-size: 30px;
  }
}
