.vacation-page__modal-container > .modal-dialog {
  width: 390px;
}

.vacation-page__modal-header {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.vacation-page__icon {
  color: #d91616;
  font-size: 36px;
}

.vacation-page__modal-title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 8px;
}

.vacation-page__modal-body {
  color: #616161;
  font-weight: bold;
  padding-top: 8px !important;
}

.vacation-page__modal-checkbox-container {
  align-items: center;
  display: flex;
  margin-top: 12px;
}
.vacation-page__modal-checkbox-container > input {
  accent-color: #bf8f00;
  margin: 0px;
  margin-right: 8px;
}
.vacation-page__modal-checkbox-container > label {
  color: #616161;
  font-size: 16px;
  margin-bottom: 0px;
  user-select: none;
}

.vacation-page__modal-footer {
  padding: 24px !important;
  padding-top: 0px !important;
}

.vacation-page__modal-footer > :not(:first-child) {
  margin-top: 12px !important;
}

.vacation-page__btn {
  border-radius: 4px;
  border: 1px solid #f15d42;
  display: flex;
  display: flex;
  justify-content: center;
  margin: 0px !important;
  padding: 4px 12px;
  width: 100% !important;
}
.vacation-page__btn:disabled {
  opacity: 0.5;
}

.vacation-page__primary-btn {
  background-color: #f15d42;
  color: #ffffff;
}

.vacation-page__secondary-btn {
  background-color: #ffffff;
  color: #f15d42;
}

.vacation-page__error-icon {
  color: #d91616;
}

.vacation-page__warning-icon {
  color: #bf8f00;
}
