/* ปรับแต่ง Toast container */
.Toastify__toast {
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;
  font-family: 'DB Helvethaica X', sans-serif;
  font-size: 22px;
  width: auto;
  min-height: 50px;
}

.Toastify__toast-icon {
  margin: 0 !important;
  padding: 0 !important;
  width: 16px !important;
}

.Toastify__close-button {
  position: relative !important;
  top: 2px !important;
  right: 0 !important;
}

/* Toast success */
.Toastify__toast--success {
  color: #079455 !important;
  background-color: #e6f4ea !important;
  border: 1px solid #dcfae6 !important;
  box-shadow: 0px 1px 3px 0px #0a0d121a !important;

  .Toastify__toast-icon {
    color: #079455 !important;
  }

  .Toastify__close-button {
    color: #079455 !important;
  }
}

/* Toast error */
.Toastify__toast--error {
  color: #d92d20 !important;
  background-color: #fee4e2 !important;
  border: 1px solid #fecdca !important;
  box-shadow: 0px 1px 3px 0px #0a0d121a !important;

  .Toastify__toast-icon {
    color: #d92d20 !important;
  }

  .Toastify__close-button {
    color: #d92d20 !important;
  }
}

/* Toast warning */
.Toastify__toast--warning {
  color: #dc6803 !important;
  background-color: #fef0c7 !important;
  border: 1px solid #fedf89 !important;
  box-shadow: 0px 1px 3px 0px #0a0d121a !important;

  .Toastify__toast-icon {
    color: #dc6803 !important;
  }

  .Toastify__close-button {
    color: #dc6803 !important;
  }
}

/* Toast info */
.Toastify__toast--info {
  color: #265ed6 !important;
  background-color: #f0f5ff !important;
  border: 1px solid #c3d8ff !important;
  box-shadow: 0px 1px 3px 0px #0a0d121a !important;

  .Toastify__toast-icon {
    color: #265ed6 !important;
  }

  .Toastify__close-button {
    color: #265ed6 !important;
  }
}
