.gallery-wrapper {
  display: block;
  min-height: 1px;
  width: 100%;
  border: 1px solid #dddddd;
  overflow: auto;
  text-align: center;
}

.button-wrapper {
  /* padding-top: 30px; */
}

.status-button {
  cursor: pointer;
  margin: 0 20px 0 20px;
  text-decoration: none;
}
.status-button:focus {
  outline: none;
}

#accept-button, .thumbs-up {
  color: #337ab7;
}
#accept-button:hover {
  color: #1e5280;
}

#reject-button, .thumbs-down {
  color: #e02c1e;
}
#reject-button:hover {
  color: #b71b0f;
}
.pa-5 {
  padding: 5px;
}
.btn-accept {
  background-color: #fff;
  color: #4caf50;
}
.btn-accept:hover {
  background-color: #fff;
  color: #38853b;
}
.btn-accept-selected {
  background-color: #4caf50;
  color: #fff;
}
.btn-accept-selected:hover {
  background-color: #38853b;
  color: #fff;
}
.btn-accept-selected:focus {
  background-color: #38853b;
  color: #fff;
}

.btn-reject {
  background-color: #fff;
  color: #e02c1e;
}
.btn-reject:hover {
  background-color: #fff;
  color: #bf2317;
}
.btn-reject-selected {
  background-color: #e02c1e;
  color: #fff;
}
.btn-reject-selected:hover {
  background-color: #bf2317;
  color: #fff;
}
.btn-reject-selected:focus {
  background-color: #bf2317;
  color: #fff;
}

.btn-warn {
  background-color: #fff;
  color: #ff9800;
}
.btn-warn:hover {
  background-color: #fff;
  color: #ff9800;
}
.btn-warn-selected {
  background-color: #ff9800;
  color: #fff;
}
.btn-warn-selected:hover {
  background-color: #ff9800;
  color: #fff;
}
.btn-warn-selected:focus {
  background-color: #ff9800;
  color: #fff;
}

.nps-page .btn {
  border-radius: 10px;
}

.job-acceptance-result .nps-page.content {
  background-color: #fff;
  border-radius: 10px;
}

.job-acceptance-result .nps-page.content .submit-button {
  margin-left: 45px;
  margin-right: 50px;
}

.job-acceptance-result .success-page {
  background-color: #fff;
  border-radius: 10px;
}

.job-acceptance-result .success-page .back-button {
  background: #fff;
  border: 1px solid #dc3545;
  color: #dc3545;
  /* box-shadow: 0 2px 1px 0 rgb(0 0 0 / 8%); */
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  text-align: center;
  width: 150px;
}

.job-acceptance-result .success-page .guarantee-button {
  background: #fff;
  border: 1px solid #f86901;
  color: #f86901;
  border-radius: 5px;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  text-align: center;
  width: 150px;
}

.job-acceptance-result .card{
  border-radius: 10px;
}

#list-results .checkbox * {
  cursor: pointer;
}
#list-results .checkbox {
  /* padding-left: 20px; */
  width: 100px;
  height: 20px;
  margin-top: 1px;
}

#list-results .checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

#list-results .checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  margin-left: -20px;
  border: 1px solid #555;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

#list-results .checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 2px;
  margin-left: -21px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #555555;
}

#list-results .checkbox input[type="checkbox"]{
  opacity: 0;
  z-index: 1;
  width: 20px;
  height: 20px;
}


#list-results .checkbox input[type="checkbox"]:checked + label::after{
  font-family: "FontAwesome";
  content: "\f00c";
}

#list-results .checkbox.checkbox-circle label::before {
  border-radius: 30%;
}

#list-results .checkbox.checkbox-inline {
  margin-top: 0;
}

#list-results .checkbox-orange input[type="checkbox"]:checked + label::after {
  color: #fff;
}

#list-results .checkbox-orange input[type="checkbox"] + label::before {
  background-color: #f86901;
  border-color: #ff9800;
}

input[type="checkbox"].styled:checked + label:after {
  font-family: 'FontAwesome';
  content: "\f00c";
}

#list-results .customer-name label {
  font-weight: normal;
  vertical-align: middle;
  margin-bottom: 0px;
}

@media screen and (min-width:320px)  {
  .job-acceptance-result {
    background-color: #f5f5f5;
    padding: 20px;
  }
  .job-acceptance-result .row.button .form-group:first-child {
    /* margin-left: 15px; */
    margin-bottom: 0px;
  }
  .job-acceptance-result .row.button .form-group:last-child {
    margin-bottom: 0px;
  }
  .job-acceptance-result .row.button .form-group .nps-btn {
    margin: 0px 3px;
  }
}

@media (min-width:600px)  {
  .job-acceptance-result {
    background-color: #f5f5f5;
    padding: 50px 10%;
  }
}