.red-bg{
    background :red;
  }
  
  .green-bg{
    background :#8be28b;
  }
  
  .swal-text{
      font-size: 30px;
  }

  .swal-footer{
        text-align : center;
  }

  .swal-button{
      font-size: 18px;
  }

  /* .swal-button:hover{
    background-color: none;
  }

  .swal-button:not([disabled]):hover {
    background-color: transparent;
} */

  .swal-button:focus {
    outline: none;
    box-shadow: none;
}

.job-status-title {
  font-size: 22px;
  font-weight: bold;
  color: #f86901;
}