.status {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 90%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.status-created {
  background-color: #f8f9fa;
  color: #343a40;
}

.status-assigned {
  background-color: #ffc107;
  color: #343a40;
}

.status-survey-started {
  background-color: #17a2b8;
}

.status-survey-finished {
  background-color: #007bff;
}

.status-installation-requested {
  background-color: #6c757d;
}

.status-installation-confirmed {
  background-color: #800080;
}

.status-installation-started {
  background-color: #000080;
}

.status-installation-finished {
  background-color: #0000FF;
}

.status-installation-accepted {
  background-color: #28a745;
}

.status-installation-rejected {
  background-color: #dc3545;
}

.status-paid-to-contractor {
  background-color: #2a940a;
}

.status-request-quotation {
  background-color: #9DCD10;
  color: #343a40;
}

.status-exists-quotation {
  background-color: #fd79a8;
}

.status-contractor_no_view {
  background-color: #BB740D;
}

.status-paid {
  color: #1ABC9C;
}

.status-deleted {
  background-color: #f8f9fa;
  color: #999;
}

.icon-button {
  font-size: 120%;
}

.pagination-bar {
  float: left;
}

.pagination-summary {
  float: left;
  padding-left: 10px;
  padding-top: 5px;
  margin: 20px 0;
}