
.row .job{
    margin-top: 40px;
}

.job {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
}

.job-content .list-group{
    padding: 10px;
    margin-bottom: 0;
    word-break: break-all;
    overflow: hidden;
}

.list-group .ellipsis{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-group-item .flex{
    display: flex;
}

.split-pay{
    margin-left: auto;
    padding: 0 14px;
    background-color: black;
    color: white;
    border-radius: 7px;
}

.text-center{
    text-align: center;
}

.user-select-none {
    user-select: none;
}
