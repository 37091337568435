
.row .job{
    margin-top: 40px;
}

.job-content .list-group{
    padding: 10px;
    margin-bottom: 0;
    word-break: break-all;
    overflow: hidden;
}

.job a{
    cursor: pointer;
}

.list-group .ellipsis{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-group-item {
    border: 0 !important;
}

.list-group-item.adjust-flex{
    display: flex;
}