.expense-table-container {
  width: 100%;
  border: 1px solid;
  border-color: rgba(37, 40, 43, 0.25);
}

.expense-table-container > .expense-table-container-header {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 0px 1rem;
  height: 32px;
  color: gray;
  font-weight: 700;
  background-color: rgba(37, 40, 43, 0.1);
}

.expense-table-container-header span {
  color: black;
}

.expense-table-container-header > .remark {
  font-size: 14px;
  color: red;
}

.expense-table-container > .expense-table-container-content {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  padding: 1rem;
}

.expense-table-container-content > table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
}

table tr > .flexible {
  width: 1%;
}

table tr > th {
  height: 32px;
  background-color: rgba(37, 40, 43, 0.1);
}

table tr > th, td {
  padding: 8px 4px 8px 4px;
}

table tr > th:first-child, td:first-child {
  padding-left: 8px;
}

table tr > th:last-child, td:last-child {
  padding-right: 8px;
}

table tr > td {
  height: 40px;
  border-bottom: 1px solid rgba(37, 40, 43, 0.25);
}

.flex {
  display: flex;
}

.w-100 {
  width: 100%;
}

.ml-auto {
  margin-left: auto;
}

.mt-8 {
  margin-top: 32px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.pt-0 {
  padding-top: 0 !important;
}

.whitespace-nowrap {
  white-space: nowrap;
}