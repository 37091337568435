.search-jobs-page__card-header-title {
  margin: 0px !important;
}

.search-jobs-page__card-content {
  padding: 24px 32px !important;
}

.search-jobs-page__filter-row {
  display: flex;
  margin-top: 12px;
}

@media (max-width: 768px) {
  .search-jobs-page__filter-row {
    display: flex;
    flex-direction: column;
  }
  .search-jobs-page__filter-row .search-jobs-page__filter-label-col:not(:first-child) {
    margin-left: 0 !important;
  }
}

.search-jobs-page__filter-row[visible='true'] {
  display: none;
}
.search-jobs-page__filter-row .search-jobs-page__filter-label-col:not(:first-child) {
  margin-left: 64px;
}

.search-jobs-page__input-group {
  flex: 1;
}
.search-jobs-page__input-group input {
  height: 40px;
}

.search-jobs-page__search-btn {
  background-color: var(--text-info-color);
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  border-width: 0px;
  color: var(--white-color);
  font-size: 20px !important;
  height: 40px;
  padding: 0px 15px;
}

.search-jobs-page__toggle-filter-btn {
  background-color: var(--white-color);
  border-width: 0px;
  color: var(--black-color);
  cursor: pointer;
  height: 40px;
  margin-left: 20px;
}
.search-jobs-page__toggle-filter-btn[active='true'] {
  color: var(--text-warning-color);
}
.search-jobs-page__toggle-filter-btn i {
  font-size: 14px;
  margin-left: 8px;
}

.search-jobs-page__filter-label-col {
  align-items: center;
  display: flex;
  flex: 1;
}

.search-jobs-page__filter-input-col {
  align-items: center;
  display: flex;
  flex: 4;
}
.search-jobs-page__filter-input-col > * {
  flex: 1;
}
.search-jobs-page__filter-input-col > :nth-child(2) {
  margin-left: 12px;
}

.search-jobs-page__space-between {
  display: flex;
  justify-content: space-between;
}

.search-jobs-page__checkbox {
  align-items: center;
  display: flex;
  height: 40px;
}
.search-jobs-page__checkbox input {
  accent-color: var(--text-info-color);
  height: 18px;
  margin-right: 8px !important;
  margin: 0px;
  width: 18px;
}
.search-jobs-page__checkbox label {
  margin: 0px;
}

.search-jobs-page__disabled {
  opacity: 0.5;
}

.search-jobs-page__clear-filter-btn,
.search-jobs-page__clear-filter-btn:disabled {
  border-radius: 4px;
  color: var(--text-medium-color);
  height: 40px;
  width: 100px;
  border-width: 0px;
}
.search-jobs-page__clear-filter-btn:disabled {
  background-color: var(--white-color);
  color: var(--border-default-color);
  cursor: not-allowed;
}

.search-job-table {
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
}

.search-job-table::-webkit-scrollbar {
  width: 10px;
}

.search-job-table::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.search-job-table::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1);
}

.search-jobs-page__table {
  border-bottom: 0.5px solid var(--divider-on-white-color);
  font-size: 18px;
  margin-bottom: 16px;
  margin-top: 36px;
  width: 100%;
}
.search-jobs-page__table th {
  background-color: var(--divider-on-white-color);
  border: 0.5px solid var(--border-default-color);
  height: 40px;
  padding: 0px !important;
}
.search-jobs-page__table th > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-jobs-page__table th i {
  font-size: 14px;
  margin-left: 5px;
}
.search-jobs-page__table tbody {
  color: var(--text-strong-color);
}
.search-jobs-page__table td {
  background-color: var(--layer-minimal-gray);
  border: 0.5px solid var(--border-default-color);
  vertical-align: top;
}
.search-jobs-page__table td:first-child {
  position: sticky;
  left: 0;
}

.search-jobs-page__clickable-table-header {
  cursor: pointer;
}

.search-jobs-page__badge {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.search-jobs-page__job-link,
.search-jobs-page__job-link:hover,
.search-jobs-page__job-link:focus {
  color: var(--text-strong-color);
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
}

.search-jobs-page__table-small-text {
  font-size: 14px;
}

td.search-jobs-page__job-description,
td.search-jobs-page__job-constractor,
td.search-jobs-page__job-customer {
  width: 200px;
  white-space: normal;
  word-wrap: break-word;
}

.search-job-table td {
  padding: 4px 8px;
}

td.search-jobs-page__job-type {
  min-width: 62px;
}

td.search-jobs-page__transfer {
  min-width: 88px;
}

td.search-jobs-page__sale-model {
  min-width: 78px;
}

td.search-jobs-page__installment {
  min-width: 39px;
}

.search-jobs-page__empty-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
}

.search-jobs-page__empty-img {
  height: 160px !important;
}

.search-jobs-page__empty-text {
  color: var(--border-default-color);
}

.search-jobs-sticky-header {
  position: sticky;
  left: 0;
}
