.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal .product-modal-container {
  position: fixed;
  background: white;
  width: 90%;
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 10px;
   /* Safari 3-4, iOS 1-3.2, Android 1.6- */
   -webkit-border-radius: 3px;
   /* Firefox 1-3.6 */
   -moz-border-radius: 3px;
   /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
   border-radius: 3px;
}

.modal .product-modal-header {
  /* height: 10%; */
  border-bottom: 1px solid rgb(204, 204, 204);
}

.modal .product-modal-body {
  height: 80%;
  overflow-y: auto;
  padding-top: 5px;
}

.modal .product-modal-footer {
  /* height: 10%; */
  text-align: right;
  border-top: 1px solid rgb(204, 204, 204);
}

.show {
  display: block;
}

.hide {
  display: none;
}