nav.navigation {
  display: flex;
}

ul.simple-pagination > li.disabled {
  pointer-events: none;
}

ul.simple-pagination > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  max-width: 40px;
  cursor: pointer;
}