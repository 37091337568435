/* SwalCustomContainer */
.swal-custom-container-benefit {
  padding: 0 !important;
  width: 100% !important;
}

/* SwalCustomPopup */
.swal-custom-popup-benefit {
  border-radius: 15px !important;
  padding: 20px !important;
  width: 600px !important;

  /* .swal2-icon.swal2-info {
    color: #ffffff;
    background-color: #265ed6;
    border-color: #265ed6;
    width: 50px;
    height: 50px;
  } */

  /* .swal2-icon-content {
    transform: rotate(180deg);
  } */

}

.swal-icon-back-benefit {
  width: 50px !important;
  height: 50px !important;
}

.swal-icon-save-benefit {
  color: white !important;
  border-color: white !important;
  background-color: #265ed6 !important;
  width: 50px !important;
  height: 50px !important;
  transform: rotate(180deg) !important;
}

.swal-icon-delete-benefit {
  color: white !important;
  border-color: white !important;
  background-color: #265ed6 !important;
  width: 50px !important;
  height: 50px !important;
  transform: rotate(180deg) !important;
}

/* SwalCustomTitle */
.swal-custom-title-benefit {
  color: #265ed6 !important;
  font-weight: bold !important;
}

/* SwalCustomText */
.swal-custom-text-benefit {
  color: #6c757d !important;
  margin-top: 10px !important;
}

/* SwalButtonsRight */
.swal-buttons-right-benefit {
  justify-content: flex-end !important;
  margin: 0 !important;
}

/* SwalConfirmButton */
.swal-confirm-button-benefit {
  background-color: #265ed6 !important;
  color: #fff !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
}

/* SwalCancelButton */
.swal-cancel-button-benefit {
  background-color: #fff !important;
  color: #265ed6 !important;
  border-radius: 8px !important;
  padding: 10px 20px !important;
}