.upload-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  width: 108px;
  height: 108px;
  background: #ffffff;
  border: 1px dashed #d1d3d4;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;

  cursor: pointer;
}

.upload-preview-container {
  box-sizing: border-box;
  display: flex;
  justify-content: end;

  padding: 8px;
  gap: 4px;
  width: 108px;
  height: 108px;
  background: #ffffff;
  border: 1px dashed #d1d3d4;
  border-radius: 6px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.upload-preview-container:hover > .upload-box-preview-content {
  display: block;
}

.upload-content {
  color: #ff8e3b;
}

.upload-content > img {
  height: 30px;
  width: 30px;
}

.upload-accept {
  margin-top: 6px;
  color: #616161;
  font-size: 16px;
}

.upload-button {
  display: flex;
  cursor: pointer;

  justify-content: center;
  align-items: center;
  gap: 4px;

  width: 128px;
  height: 40px;

  background: #ffffff;
  border: 1px solid #ff8e3b;
  border-radius: 6px;

  flex: none;
  order: 0;
  flex-grow: 0;
  color: #ff8e3b;
}

.upload-button > img {
  max-width: 20px;
  max-height: 20px;
}

.upload-button-preview {
  display: flex;
}

.upload-button-preview-title {
  color: var(--text-info-color);
}

.upload-preview-icon {
  cursor: pointer;
  color: #d91616;
  margin: 4px;
}

.upload-box-preview-content {
  display: none;
  position: relative; /* Ensure the container is positioned relative */
}

.upload-box-preview-icon {
  cursor: pointer;
  color: #d91616;
  background: rgba(179, 179, 179, 0.4);
  border-radius: 99px;
  padding: 5px;
}

.Select.is-disabled > .Select-control {
  background-color: #fff5d940;
}
