.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.search-container .search-icon {
  position: relative;
  right: 30px;
  /* color: #f3873a; */
  color: #c8ced3;
}

/* Center the search bar */
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
  width: 100%;
  max-width: 500px;
}

/* Style the input field */
.search-input {
  width: 100%;
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

/* Style the input focus */
.search-input:focus {
  border-color: #f3873a;
  box-shadow: 0px 0px 10px rgba(247, 145, 50, 0.4);
}

/* Responsive design */
@media (max-width: 600px) {
  .search-bar {
    max-width: 100%;
  }

  .search-input,
  .search-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
