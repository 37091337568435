/*the container must be positioned relative:*/
.autocomplete {
    position: relative;
    display: inline-block;
  }
  
.autocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99999;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
}

.autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: #fff; 
    border-bottom: 1px solid #d4d4d4; 
    border-top: 1px solid #d4d4d4; 
}

/*when hovering an item:*/
.autocomplete-items div:hover {
    background-color: DodgerBlue;
    color: #ffffff; 
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
    background-color: #e9e9e9 !important; 
}


.autocomplete-container {
    margin: 0 auto;
    max-width: 600px;
  }
  
  .autosuggest-container {
    position: relative;
  }
  
  .autosuggest-input,
  .autosuggest-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .autosuggest-textarea {
    resize: both; /* หรือ 'vertical' ถ้าต้องการให้ขยายได้เฉพาะแนวตั้ง */
  }
  
  .autosuggest-suggestions-container-input {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none; /* ลบจุดจากรายการ */
    padding: 0;
    margin: 0;
  }

  .autosuggest-suggestions-container-textarea {
    position: absolute;
    top: 90% !important;
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #ccc;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none; /* ลบจุดจากรายการ */
    padding: 0;
    margin: 0;
  }

  .autosuggest-suggestions-container-input ul,
  .autosuggest-suggestions-container-textarea ul {
    list-style-type: none; /* ลบจุดจากรายการ */
    padding: 0;
    margin: 0;
  }
  
  .autosuggest-suggestion {
    padding: 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  
  .autosuggest-suggestion:last-child {
    border-bottom: none;
  }
  
  .autosuggest-suggestion--highlighted {
    background: #f0f0f0;
  }
  
  .display-none {
    display: none;
  }