/* Main Colors */
.background-color-orange {
  background-color: #f86901;
}
.background-color-brown {
  background-color: #ae5d0e;
}
.background-color-black {
  background-color: #000;
}

:root {
  --text-strong-color: #292b33;
  --border-default-color: #bbbfcc;
  --divider-on-white-color: #edeef2;
  --button-default-color: #265ed6;
  --icon-disable-color: #dcdee6;
  --text-medium-color: #545766;
  --text-warning-color: #bf8f00;
  --text-info-color: #265ed6;
  --layer-minimal-gray: #f6f7f9;
  --white-color: #ffffff;
  --black-color: #000000;
}

.navbar-brand.user-name-text i {
  padding-right: 10px;
  line-height: 0.75em;
  vertical-align: -15%;
}

.navbar-brand.user-name-text img {
  width: 40px;
  height: 40px;
  display: unset;
  margin-right: 10px;
  border-radius: 50%;
}

body {
  /* Keyword values */
  overscroll-behavior: auto; /* default */
  overscroll-behavior: contain;
  overscroll-behavior: none;

  /* Two values */
  overscroll-behavior: auto contain;

  /* Global values */
  overflow: inherit;
  overflow: initial;
  overflow: unset;
}

html {
  font-size: 10px;
}
.switch-role {
  margin-top: -5px !important;
}
.switch-role-text a {
  font-size: 1.3em !important;
}

.dropdown .dropdown-menu {
  padding: 2px;
  min-width: 50px !important;
}

.switch-role.is-final{
  color: #19AB27;
  font-size: 1.1em;
  font-weight: bold;
}
/* .form-group {
  margin-bottom: 5px;
} */
.card form [class*="col-"] .form-group {
  margin-bottom: 5px;
}
/* MOBILE */

@media (max-width: 767px) {
  /* xs */
  .p-left,
  .p-right {
    text-align: center;
  }
  .calendar-action-mobile {
    display: block;
  }
  .calendar-action {
    display: none;
  }
  .event-mobile {
    display: block;
    font-size: 16px;
  }
  .event-non-mobile {
    display: none;
  }
  .row-calendar {
    margin-left: -42px;
    margin-right: -42px;
  }
  button.btn.btn-calendar {
    font-size: 12px;
  }
  .p4-toggle {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .p4-toggle span {
    line-height: 1em;
    top: 15px;
  }
  .navbar-brand.user-name-text {
    font-size: 18px;
  }
  .navbar-brand.user-name-text i {
    font-size: 1.33333333em;
  }
  .navbar-brand.user-name-text img {
    width: 30px;
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* sm */
  .p-left,
  .p-right {
    text-align: center;
  }
  .calendar-action-mobile {
    display: none;
  }
  .calendar-action {
    display: block;
  }
  .event-mobile {
    display: block;
    font-size: 16px;
  }
  .event-non-mobile {
    display: none;
  }
  .row-calendar {
    margin-left: -42px;
    margin-right: -42px;
  }
  button.btn.btn-calendar {
    font-size: 12px;
  }
  .p4-toggle {
    padding-top: 15px;
    padding-bottom: 50px;
  }
  .p4-toggle span {
    line-height: 1.2em;
  }
  .navbar-brand.user-name-text {
    font-size: 1.5em;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* md */
  .p-right {
    text-align: right;
  }
  .p-left {
    text-align: left;
  }
  .calendar-action-mobile {
    display: none;
  }
  .calendar-action {
    display: block;
  }
  .event-mobile {
    display: none;
  }
  .event-non-mobile {
    display: block;
  }
  .p4-toggle {
    padding-top: 15px;
    padding-bottom: 0;
  }
  .p4-toggle span {
    line-height: 1.5em;
  }
  .navbar-brand.user-name-text {
    font-size: 1.5em;
  }
}

@media (min-width: 1200px) {
  /* lg */
  .p-right {
    text-align: right;
  }
  .p-left {
    text-align: left;
  }
  .calendar-action-mobile {
    display: none;
  }
  .calendar-action {
    display: block;
  }
  .event-mobile {
    display: none;
  }
  .event-non-mobile {
    display: block;
  }
  .p4-toggle {
    padding-top: 15px;
    padding-bottom: 0;
  }
  .p4-toggle span {
    line-height: 1.5em;
  }
  .navbar-brand.user-name-text {
    font-size: 1.5em;
  }
}

/* COMMON */

@font-face {
  font-family: "DB Helvethaica X";
  src: url("./fonts/DB-Helvethaica-X.eot");
  src: url("./fonts/DB-Helvethaica-X.eot?#iefix") format("embedded-opentype"),
    url("./fonts/DB-Helvethaica-X.woff") format("woff"),
    url("./fonts/DB-Helvethaica-X.ttf") format("truetype"),
    url("./fonts/DB-Helvethaica-X.svg#webfont") format("svg");
}

body {
  font-family: "DB Helvethaica X", "Montserrat", "Helvetica Neue", Helvetica,
    Arial, sans-serif;
}

.c-pointer {
  cursor: pointer;
}

/* LAYOUT */

.item-card {
  margin-top: 0;
  margin-bottom: 5px;
}

.main-panel.full-page {
  float: none;
  width: 100%;
}

.main-panel.full-page .content {
  padding-top: 5px;
}

.main-panel.p4-main > .content,
.main-panel.p4-main-contractor > .content {
  margin-top: 40px;
}

.overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
}

.start-calendar {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

/* LIST ITEM */

.list-group-item {
  border: 0;
  padding-left: 0;
  border-top: 1px solid;
  border-color: rgba(37, 40, 43, 0.1);
}

.list-group .list-group-item:first-child {
  border: 0;
}

.list-group .list-group-item a {
  color: #2895f1;
  cursor: pointer;
  text-decoration: none;
}

.list-group.list-group-header {
  padding: 0;
  margin: 0;
}

.list-group.list-group-body .glyphicon {
  padding-top: 0;
  font-size: 25px;
  vertical-align: middle;
}

.list-group-panel {
  border: 1px solid #ccdbeb;
  border-radius: 0;
}

/* NUMERIC COMPONENT */

.react-numeric-input input,
.react-numeric-input {
  width: 100%;
}

/* FONT SIZE */

body {
  font-size: 200%;
}

button.btn {
  font-size: 100%;
}

.p4-date a,
.date-circle a {
  color: #000;
  cursor: pointer;
}

/* SIDEBAR */

.sidebar-background {
  background-image: url("../img/sidebar-bg.jpg");
}

/* FORM */

.scg-p4.form-group.is-focused .form-control {
  background-image: linear-gradient(60deg, #ef5350, #e53935),
    linear-gradient(#d2d2d2, #d2d2d2);
}

.scg-p4.form-group .form-control {
  height: 100%;
  width: 100%;
}

.p4-dropdown {
  margin-top: 5px;
}

.p4-select.Select {
  padding-top: 5px;
}

.p4-select .Select-control {
  padding-bottom: 5px;
}

.p4-select.Select--multi .Select-value {
  background-color: rgba(255, 0, 0, 0.08);
  border-radius: 2px;
  border: 1px solid rgba(255, 0, 0, 0.24);
  color: #f44336;
}

.p4-select.Select--multi .Select-value-icon:hover,
.p4-select.Select--multi .Select-value-icon:focus {
  background-color: rgba(255, 0, 0, 0.08);
  color: #f44336;
}

/* .p4-select.Select--single > .Select-control .Select-value, */

.p4-select .Select-placeholder {
  line-height: 40px;
}

/* DATE PICKER */

.en-daterangpicker .DateRangePickerInput--disabled,
.en-datepicker .SingleDatePickerInput--disabled,
.en-datepicker .DateInput--disabled {
  background: #fff;
}

.DateRangePicker,
.DateRangePicker .DateRangePickerInput,
.SingleDatePicker .DateInput,
.SingleDatePicker {
  width: 100%;
}

.SingleDatePickerInput {
  border: 0;
}

.en-daterangpicker .DateRangePickerInput {
  padding-top: 0;
  padding-bottom: 0;
}

.en-daterangpicker .DateRangePickerInput {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  line-height: 1.42857143;
}

.en-daterangpicker .DateRangePickerInput .DateInput {
  background-color: transparent;
  padding-top: 3px;
  padding-bottom: 0;
  font-size: 1em;
}

.SingleDatePicker .DateInput {
  padding-left: 0;
  padding-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  padding: 6px 12px;
  line-height: 1.42857143;
}

.SingleDatePicker .DateInput__display-text--focused {
  background: none;
  border-color: none;
  border-radius: 0;
  color: #000;
}

.SingleDatePicker .DateInput__display-text {
  padding: 0;
  font-size: 1.1em;
}

.SingleDatePicker .DateInput__display-text--disabled {
  font-style: normal;
}

/* VALIDATION */

.Select.is-invalid-input .Select-control,
textarea.is-invalid-input,
input.is-invalid-input {
  background-color: rgba(236, 88, 64, 0.1);
  border-color: #e53935;
}

span.input-error {
  color: #e53935;
}

/* CALENDAR */

.rbc-month-row {
  overflow: visible;
}

.rbc-event-content {
  font-size: 0.9em;
  line-height: 1;
}

.rbc-row-content {
  height: auto;
  min-height: 100px;
}

.rbc-calendar.p4-calendar .last-day-event {
  border-right: 1px solid #ddd;
}

.rbc-calendar.p4-calendar .vacation-date-0 {
  background: #ff817b;
  border-left: none;
}

.vacation-half-day-event.rbc-event.rbc-selected,
.vacation-half-day-event,
.vacation-all-day-event {
  background-color: #ff817b;
  text-align: center;
  color: #000;
}

.vacation-all-day-event.rbc-event.rbc-selected,
.vacation-all-day-event .rbc-event-content {
  background-color: #ff817b;
}

.rbc-calendar.p4-calendar .holiday-date {
  background: #fefee1;
}

.holiday-event {
  background-color: #fefee1;
  text-align: center;
  color: #000;
}

.holiday-event.rbc-event.rbc-selected,
.holiday-event .rbc-event-content {
  background-color: #fefee1;
}

.job-event {
  background-color: #e0faff;
  color: #000;
}

.job-event.rbc-event.rbc-selected,
.job-event .rbc-event-content {
  background-color: #e0faff;
}

.job-assigned-event {
  background-color: #8be28b;
  color: #000;
}

.job-assigned-event.rbc-event.rbc-selected,
.job-assigned-event .rbc-event-content {
  background-color: #8be28b;
}

.job-unconfirm-event {
  background-color: #d0b3e1;
  color: #000;
}

.job-unconfirm-event.rbc-event.rbc-selected,
.job-unconfirm-event .rbc-event-content {
  /* background-color: #7A58BF; */
  background-color: #d0b3e1;
}
.contractor-job-event {
  background-color: #ffa366;
  color: #000;
}

.contractor-job-event.rbc-event.rbc-selected,
.contractor-job-event .rbc-event-content {
  /* background-color: #7A58BF; */
  background-color: #ffa366;
}

/* MONTH PICKER */

.p4-calendar .rmp-popup {
  top: 0;
  right: 0;
}

/* REMARK */

ul.remark {
  list-style-type: none;
}

/* ARROW */

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #ffb2ae;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #ffb2ae;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 20px solid #ffb2ae;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 20px solid #ffb2ae;
}

/* GOOGLE MAPS */

#pac-input {
  background-color: #fff;
  font-size: 15px;
  font-weight: 300;
  margin-top: 10px;
  margin-left: 5px;
  padding: 0 11px 0 13px;
  text-overflow: ellipsis;
  width: 50%;
  line-height: 2;
}

#pac-input:focus {
  border-color: #4d90fe;
}

/* LOADER */

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.loader-img {
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: absolute;
  margin: auto;
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: rgba(255, 255, 255, 0.6);
}

.loader-img img {
  width: 250px;
  height: 250px;
}

.loader {
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  z-index: 10000;
  top: 0;
  width: 100%;
}

.loader_overlay {
  width: 150px;
  height: 150px;
  background: transparent;
  -webkit-box-shadow: 0px 0px 0px 1000px rgba(255, 255, 255, 0.67),
    0px 0px 19px 0px rgba(0, 0, 0, 0.16) inset;
  box-shadow: 0px 0px 0px 1000px rgba(255, 255, 255, 0.67),
    0px 0px 19px 0px rgba(0, 0, 0, 0.16) inset;
  border-radius: 100%;
  z-index: -1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs {
  z-index: -2;
  width: 100px;
  height: 100px;
  top: -120px !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs__top {
  position: relative;
  width: 100px;
  height: 100px;
  -webkit-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
  -webkit-animation: rotate 10s infinite linear;
  animation: rotate 10s infinite linear;
}

.loader_cogs__top div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.loader_cogs__top div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.loader_cogs__top div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loader_cogs__top div.top_part {
  width: 100px;
  border-radius: 10px;
  position: absolute;
  height: 100px;
  background: #f98db9;
}

.loader_cogs__top div.top_hole {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs__left {
  position: relative;
  width: 80px;
  -webkit-transform: rotate(16deg);
  transform: rotate(16deg);
  top: 28px;
  -webkit-transform-origin: 40px 40px;
  transform-origin: 40px 40px;
  animation: rotate_left 10s 0.1s infinite reverse linear;
  left: -24px;
  height: 80px;
}

.loader_cogs__left div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.loader_cogs__left div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.loader_cogs__left div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loader_cogs__left div.left_part {
  width: 80px;
  border-radius: 6px;
  position: absolute;
  height: 80px;
  background: #97ddff;
}

.loader_cogs__left div.left_hole {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.loader_cogs__bottom {
  position: relative;
  width: 60px;
  top: -65px;
  -webkit-transform-origin: 30px 30px;
  transform-origin: 30px 30px;
  -webkit-animation: rotate_left 10.2s 0.4s infinite linear;
  animation: rotate_left 10.2s 0.4s infinite linear;
  -webkit-transform: rotate(4deg);
  transform: rotate(4deg);
  left: 79px;
  height: 60px;
}

.loader_cogs__bottom div:nth-of-type(1) {
  -webkit-transform: rotate(30deg);
  transform: rotate(30deg);
}

.loader_cogs__bottom div:nth-of-type(2) {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.loader_cogs__bottom div:nth-of-type(3) {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.loader_cogs__bottom div.bottom_part {
  width: 60px;
  border-radius: 5px;
  position: absolute;
  height: 60px;
  background: #ffcd66;
}

.loader_cogs__bottom div.bottom_hole {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: white;
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.padding_top_15 {
  padding-top: 15px;
}

/* Animations */

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate_left {
  from {
    -webkit-transform: rotate(16deg);
    transform: rotate(16deg);
  }
  to {
    -webkit-transform: rotate(376deg);
    transform: rotate(376deg);
  }
}

@keyframes rotate_left {
  from {
    -webkit-transform: rotate(16deg);
    transform: rotate(16deg);
  }
  to {
    -webkit-transform: rotate(376deg);
    transform: rotate(376deg);
  }
}

@-webkit-keyframes rotate_right {
  from {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  to {
    -webkit-transform: rotate(364deg);
    transform: rotate(364deg);
  }
}

@keyframes rotate_right {
  from {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg);
  }
  to {
    -webkit-transform: rotate(364deg);
    transform: rotate(364deg);
  }
}

/* TOAST */

.p4-toast {
  text-align: left;
}

/* override sweet altert */

.swal2-popup {
  font-size: 100% !important;
}

.swal2-radio {
  display: grid !important;
}

/* FORM */

.time-work .react-numeric-input input {
  height: 34px;
}

span.form-label {
  font-weight: 700;
}

select:disabled {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.form-control {
  height: 40px;
  font-size: 1em;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  background-color: #fff5d940;
}

.en-datepicker .DateInput--disabled {
  background-color: #fff5d940;
}

.en-datepicker .DateInput--disabled input:disabled {
  cursor: not-allowed;
}

/* Register Page */
.bottom-fixed {
  position: fixed;
  left: 0;
  bottom: -1px;
  right: 0;
  background-color: #fff;
  /* text-align: center; */
  box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.0785);
}

.flex-center {
  margin-left: auto !important;
  margin-right: auto !important;
  max-width: 700px !important;
  padding: 1px 30px 1px 30px;
}

.scroll-button {
  opacity: 0.3;
  background-color: #f86901;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  border: none;
}

.scroll-button:hover {
  opacity: 1;
}

.scroll-button:focus {
  outline: 0;
}

.scroll-icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
}

.table-responsive {
  overflow: auto;
}

.table-fix-head {
  overflow-y: auto;
  height: 500px;
}

.table-fix-head thead th {
  position: sticky;
  top: 0;
  z-index: 1
}

.btn.btn-primary:hover {
  box-shadow: 0 14px 26px -12px rgba(153, 153, 153, 0.42),
    0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(153, 153, 153, 0.2);
}
.btn.btn-primary {
  box-shadow: none;
}

.en-daterangpicker .DateRangePickerInput--disabled,
.en-datepicker .SingleDatePickerInput--disabled,
.en-datepicker .DateInput--disabled {
  background-color: #fff5d940;
  font-family: "DB Helvethaica X";
}

.DateInput__display-text--disabled {
  font-style: normal;
}

@media (max-width: 991px) {
  .en-daterangpicker .DateRangePickerInput .DateInput {
    font-size: 16px;
  }
}

.header-seo {
  border-radius: 5px;
  padding-left: 10px;
  /* margin: 5px; */
  /* border-bottom: 1px solid #c8ced3; */
  background-color: #f0f3f5;
  font-size: 25px;
  font-weight: 600;
}

.seo {
  border: 1px solid #c8ced3;
  border-radius: 5px;
}
.seo-form {
  padding: 10px;
}
.seo-preview-title {
  font-size: 20px;
  line-height: 1.3;
  max-width: 100%;
  /* width: 500px; */
  font-family: arial,sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1a0dab;
}
.seo-preview-link {
  font-family: arial,sans-serif;
  font-size: 16px;
  padding-bottom: 0px;
  padding-top: 1px;
  line-height: 1.57;
  display: inline-block;
}
.seo-preview-link cite {
  padding-top: 1px;
  line-height: 1.5;
  color: #006621;
  font-style: normal;
}
.seo-preview-description {
  font-family: arial,sans-serif;
  font-size: 14px;
  line-height: 1.57;
  color: #545454;
  max-width: 48em;
}
.seo-preview-description span {
  line-height: 1.57;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
}

.header-duration {
  padding-left: 10px;
  border-bottom: 1px solid #c8ced3;
  background-color: #f0f3f5;
  font-size: 20px;
  font-weight: 600;
  padding-top:10px;
  padding-bottom:10px;
}

.duration {
  border: 1px solid #c8ced3;
}
.duration-form {
  padding: 10px;
}
.duration-range {
  border: 1px solid #c8ced3;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}

.header-survey {
  padding-left: 10px;
  border-bottom: 1px solid #c8ced3;
  background-color: #f0f3f5;
  font-size: 20px;
  font-weight: 600;
  padding-top:10px;
  padding-bottom:10px;
}

.survey {
  border: 1px solid #c8ced3;
}
.survey-form {
  padding: 10px;
}

.admin-signin-logo {
  padding-top: 20px;
  padding-bottom: 15px;
}
.admin-signin-logo img {
  width: 40%;
}

.contractor-signin-logo {
  padding: 0 0 10px 0;
  text-align: center;
}

.contractor-signin-logo img {
  width: 120px;
}

.btn-icon {
  padding-right: 5px;
}

.alert-quotation {
  padding-top: 0;
  padding-bottom: 0;
}

.alert-quotation span {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 15px;
  float: left;
}

.btn-quotation {
  float: left;
  margin-left: 15px;
}

.default-button,
.default-button:hover,
.default-button:focus,
.default-button:active {
  background-color: var(--white-color);
  border-radius: 4px;
  border: 1px solid var(--button-default-color);
  box-shadow: unset;
  color: var(--button-default-color);
  margin: 0px;
  min-width: 95px;
  padding: 4px 12px;
}

.primary-button,
.primary-button:hover,
.primary-button:focus,
.primary-button:active {
  background-color: var(--button-default-color);
  border-radius: 4px;
  border: 1px solid var(--button-default-color);
  box-shadow: unset;
  color: var(--white-color);
  margin: 0px;
  min-width: 95px;
  padding: 4px 12px;
}

::placeholder {
  opacity: 0.6;
}

.sticky-top {
  /* position: -webkit-sticky;
  position: sticky; */
  position: fixed;
  top: 50px;
  width: 33.33%;
}

.row-time{
    line-height: 48px;
    text-align: center;
    /* border: 1px solid #f2f2f2; */
}

.bigger{
  width: 100%;
  border: 1px solid #f2f2f2;
}

.styles_modal__gNwvD{
  width:  555px;
}

@media (max-width: 450px) {
  .styles_modal__gNwvD{
    width:  350px;
    padding: 0px;
  }
}
.contractor-mask-input {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  height: 40px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  min-height: 1px;
}

.contractor-mask-input:disabled{
  background: #FFFCF5;
}

  .contractor-mask-input:focus{
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
  }

.input-number {
  display: inline-block;
  width: 70px;
  text-align: center;
}

.label-inline {
  padding-right: 10px;
  display: inline-block;
}

.row-disabled {
  background: #f2f3f4;
}

.label-grade {
  font-weight: 10;
}

.table-freeze {
  overflow: auto;
  max-height: 450px;
}

.table-freeze-header > thead > tr > th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.react-tagsinput-tag {
  background-color: rgba(255, 0, 0, 0.08);
  border-radius: 2px;
  border: 1px solid rgba(255, 0, 0, 0.24);
  color: #f44336;
}

a.react-tagsinput-remove {
  color: #f44336;
  padding-left: 2px;
  margin-left: 8px;
  border-left: 1px solid #ccc;
}
.react-tagsinput {
  border-radius: 4px;
}

.react-tagsinput--focused {
  border-color: #ccc;
}

.select-custom-height .Select-control {
  height: 40px;
}

.select-custom1 .Select-control {
  height: 40px;
  z-index: 9;
}

.select-custom1.z-index1 .Select-control {
  z-index: 1;
} 

.select-custom1 .Select-value {
  padding-left: 15px;
  padding-top: 1px;
  line-height: 40px;
}

.select-custom1 .Select-placeholder {
  line-height: 40px;
}

.SingleDatePicker__picker,
.select-custom1 .Select-menu-outer,
.tags-select-custom1 .Select-menu-outer {
  z-index: 9;
}

.tags-select-custom1.z-index1 .Select-control {
  z-index: 1;
} 

.swal2-popup .swal2-styled.swal2-cancel {
  border-color: black;
}

.cursor-pointer {
  cursor: pointer;
}
.position-modal-center{
    padding: 2.3vw 0px;
    margin: auto;
    text-align: center;
}
#input-password {
  width: 80%;
  background-color: #EEEEEE;
  border: 0px;
  height: 50px;
  padding: 2vw 45px 2vw 2vw;
  font-size: 2.7rem;
}
.icons-eyes {
  margin-left: -45px;
  cursor: pointer;
}
.input-text-error {
  margin: 0px 5vw 0px 6rem;
  text-align: left;
}
#input-reason {
  width: 80%;
  padding: 1vw 45px 2vw 2vw;
  font-size: 2.7rem;
  margin-left: 1.5vw;
}
#input-reason-password {
  width: 80%;
  background-color: #EEEEEE;
  border: 0px;
  height: 50px;
  padding: 2vw 45px 2vw 2vw;
  font-size: 2.7rem;
}

.Select-clear{
  display: none;
  width: 0;
}
.Select-clear-zone{
  display: none;
  width: 0 !important;
}

.text-red2 {
  color: #f44336;
}

.text-gray {
  color: gray;
}


.btn-ligth {
  background: #FFFFFF;
  border: 1px solid #000;
}

.flex-row { 
  display: flex;
  flex-direction: row; 
}

.logo-center { 
  width: 18px !important;
  height: 18px !important;
  align-self: center !important;
}

.title-text-center {
  width: 100%;
  text-align: center;
  color: #014672
}

.btn-space {
  margin-left: 0;
  margin-bottom: 0;
}

.p-0 {
  padding: 0 0 0 0 !important;
}
.btn-icon-circle {
  border-radius: 100%;
  width: 0rem;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
}
.card-installments {
  border: 2px solid #ccc !important;
  border-radius: 15px;
  padding: 1rem 2rem;
  margin-bottom: 1.5rem;
}
.content-right {
  display: flex;
  justify-content: end;
}
.btn-icon-installment:hover{
  box-shadow: none !important;
  background: white;
}
.btn-icon-installment {
  box-shadow: none !important;
  background: white;
}
@media (max-width: 700px) {
  .tableQuotaton {
    min-width: 420% !important;
  }
  .content-footer {
    min-width: 420% !important;
  }
}

div[class^="react-datepicker"], span[class^="react-datepicker"], button[class^="react-datepicker"]{
  font-family: "DB Helvethaica X" !important;
  font-size: 16px;
}

.react-datepicker__month-text--keyboard-selected{
  background: none !important;
}

.react-datepicker__day--selected, .react-datepicker__month--in-range, .react-datepicker__month--range-start {
  background: #00a699 !important;
  color: #ffffff !important;
}

.react-datepicker__month-wrapper{
  display: flex !important;
}

.react-datepicker__month-text{
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  flex: 1 0 30% !important;
  height: 40px !important;
  border-radius: 3px;
}

.react-datepicker-popper,.custom-month-picker,.react-datepicker__month-container{
  width: 100% !important;
}

.react-datepicker__month--disabled{
  color: #ccc !important; 
}

.react-datepicker__close-icon::after{
  background-color: #f44336 !important;
  font-size: 16px !important;
  padding: 0 !important;
}

.btn-next-prev-calendar {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #eee;
  border-radius: 5px;
  background: none !important;
  height: 40px;
  width: 40px;
}
.btn-next-prev-calendar > span.fa{
  color: #82888a !important;
}

.react-datepicker__header{
  background: none !important;
  border: none !important;
}

.custom-month-picker-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.custom-month-picker-input{
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 40px;
  padding: 6px 12px;
  line-height: 1.42857143;
  font-family: "DB Helvethaica X" !important;
  font-size: 18px;
  outline: none !important;
  width: 100%;
}

.d-flex{
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.d-flex > span.fa{
  color: #82888a !important;
  font-size: 16px;
}

.month-divider{
  margin: 5px
}

.wordWarp {
  word-wrap: break-word;
}

.invalid-input {
  border: 1px solid #f44336 !important;
}

.swal2-success {
  height: 60px;
  background: #E3FAEE !important;
}

.swal2-success .swal2-title {
  color: #00AE68;
}

.swal2-error {
  height: 60px;
  background: #ffe5e4 !important;
}

.swal2-error .swal2-title {
  color: #f44336;
}

.preview-template-modal {
  margin-top: 0px !important;
  padding-top: 50px;
  width: 70vw;
  height: calc(100vh - 50px);
}
.preview-template-modal .modal-content{
  height: 100%;
}

.primary-text-color {
  color: #014672 !important;
}

.custom-popup-class {
  border-radius: 16px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@media print {
	body {
		margin: 0 !important;
	}
}

.main-container {
	font-family: 'Lato';
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.ck-content {
	font-family: 'Lato';
	line-height: 1.6;
	word-break: break-word;
}

.editor-container_classic-editor .editor-container__editor {
	/* min-width:  fit-content; */
}
