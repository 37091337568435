.div_EnButton {
    margin-right: 0px;
  }

.react-tagsinput-input {
    width: 100%;
}
.box-border {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  padding: 12px 0px 12px 12px;
  border-radius: 4px;
}
.checkbox-form {
  margin: 0 30px 0 20px;
}
.text-right {
  text-align: right;
}
/*  
  .tab_EnFormGroup {
     padding: 0px !important;   
  }

  
  .card.store form [class*="col-"]{
    padding: 0px !important; 
  }
   */

.line-oa-contrainer {
  display: flex;
  gap: 1em;
  align-items: center;
  margin-bottom: 0.4em;
}

.line-oa-contrainer > .en-toggle {
  margin: 0 !important;
}