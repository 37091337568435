.no-padding {
  padding: 0px !important;
}

.checkbox-form {
  position: relative;
}

.custom-checkbox.d-center {
  width: 20px;
  margin: auto;
  position: relative;
  display: flex;
}

.custom-checkbox {
  position: relative;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: #337ab7;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 6px;
  height: 13px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.custom-checkbox.size-20x20 {
}

.custom-checkbox.size-20x20 .checkmark {
  height: 20px;
  width: 20px;
}

.custom-checkbox.size-20x20 .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 12px;
}

.circle {
  background: #ededed;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  border: 3px solid #ededed;
  box-shadow: 0px 0px 0px 1px #6f7070;
}

.circle.circle--active {
  background: #4285f4;
}

.argument-badge {
  margin: 0px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  height: 28px;
  background: #fff0e6;
  border-radius: 4px;
  width: fit-content;
  color: #f57a23;
  cursor: pointer;
}

.remove-badge {
  margin-left: 4px;
}

/* EnTest.css */
.custom-textarea {
  display: flex;
  flex-wrap: wrap;
  padding: 6px 12px;
  resize: none;
  overflow-y: scroll;
  min-height: 1em;
  line-height: 1.5;
  padding-right: 28px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  word-break: break-word;
  white-space: pre-wrap;
}

.custom-textarea::-webkit-scrollbar {
  display: none;
}

.custom-remaining-length {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0%, -50%);
  color: gray;
}

.custom-suffix {
  position: absolute;
  right: 10px;
  top: 26px;
  transform: translate(0%, -50%);
  color: gray;
}

[contentEditable=true]:empty:before {
  content: attr(placeholder);
  opacity: 0.6;
}

input.no-arrow::-webkit-outer-spin-button,
input.no-arrow::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}