.image-picker {
  /* position: relative; */
}

.image-picker img {
  width: 100%;
  height: 210px;
  object-fit: fill;
  border: 1px solid #ccc;
}

.image-picker-delete {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: #ff0000;
  cursor: pointer;
  padding: 2px 4px;
  line-height: 0.5em;
  border-radius: 50%;
}

.image-picker-delete:hover {
  box-shadow: 11 14px 26px -12px rgba(244, 67, 54, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(244, 67, 54, 0.2);
}

.image-picker-delete:hover i {
  color: #dddddd;
}

.image-picker-delete i {
  color: #ffffff;
}
