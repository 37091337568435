/* @media (min-width: 481px) and (max-width: 767px) {
  .canvas-sig{
    width: 90%;
    height: 90%;
    margin: 0 auto;
    background-color: #fff;
}
.canvas-pad {
    width: 90%;
    height: 90%;
    border: 1px solid black;
}
} */
.container-canvas {
  width: 100%;
  height: 200px;
  top: 10%;
  left: 10%;
}

.canvas-sig {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
}

.canvas-pad {
  width: 100%;
  border: 1px solid black;
  height: 100%;
}


.custom-modal {
  width: 100VW;
  height: 100VH;
  text-align: center;
  padding: 0;
}

.custom-modal .modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.custom-modal .modal-dialog {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  padding: 0;
}

.custom-modal .modal-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 2px solid #3c7dcf;
  border-radius: 0;
  box-shadow: none;
  height: 100vh;
  overflow-y: auto;
}

.custom-modal .modal .modal-dialog {
  margin-top: 0;
}

.approve-tow-form {
  /* z-index: 9; */
}

.form-control-clear {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  line-height: 42px;
  font-size: 14px;
  right: 1px;
  color: #bfbfbf;
}

.form-control-clear-text {
  z-index: 10;
  pointer-events: auto;
  cursor: pointer;
  line-height: 37px;
  font-size: 18px;
  right: 1px;
  color: #999;
}

.form-control+.form-control-feedback.form-control-clear-text {
  margin-top: 0px;
}

.input-group-btn .btn-custom {
  margin: 0px;
  padding: 6px 20px;
  font-size: 20px;
}

.approve-tow-select {
  line-height: 38px;
}

.approve-tow-select.p4-select.Select {
  padding-top: 0px;
}

.approve-tow-select.p4-select.Select .Select-placeholder, .Select--single > .Select-control .Select-value {
  line-height: 37px;
}
.approve-tow-select.p4-select.Select .Select-clear-zone:hover,
.approve-tow-select.p4-select.Select .Select-clear-zone:focus,
.approve-tow-select.p4-select.Select .Select-clear-zone:active {
  color: #999;
}

@media (min-width: 992px) {
  .contractor-filter{
    display: flex;
    justify-content: flex-end !important;
  }
}

.contractor-filter{
  display: flex;
  justify-content: flex-start;
}