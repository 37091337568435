.home{
    /* background: url() no-repeat center top; */
    background-size: cover;
    color: #ffffff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;

}

.color-overlay{
    background: rgba(0,0,0,0.40);
    padding-top: 200px;
    width: 100%;
    height: 100%;
    text-align: center;
}