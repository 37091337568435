.rmsc {
  --rmsc-main: #f48342 !important;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: #ccc !important;
  --rmsc-gray: #9c9590 !important;
  --rmsc-bg: #fff;
  --rmsc-p: 10px; /* Spacing */
  --rmsc-radius: 4px; /* Radius */
  --rmsc-h: 38px; /* Height */
}


#searcBtn {
  background: transparent linear-gradient(299deg, #EB620C 0%, #EF9609 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 11px #E8600A6F;
  border-radius: 7px;
  float: right;
  width:167px;
}
div.en-toggle.p4-toggle {
  padding-top: 0px;
}

div.toggle-group {
  float: left !important;
}



.table-job-tracking>thead>tr>th {
    border-bottom-width: 1px;
    /* font-size: 2.3rem; */
    font-weight: bold;
    background-color: white;
    border-bottom: 5px solid #f48342;
}
.table-job-tracking>tbody>tr>td {
  padding-bottom: 0px;
    /* font-size: 2.1rem; */
}
/* svg.dropdown-heading-dropdown-arrow gray {
  color: #f48342 !important;
} */
ul .tab-bu {
  display: inline;
  text-align: center;
  cursor: pointer;
}
h5.job-tracking {
  font-size:1.2em;
}
#h4-job-tracking {
  font-size:1.45em;
}
.tab-bu-a {
  display: inline-block;
  width: 45%;
  padding: .75rem 0;
  margin: 0;
  text-decoration: none;
  /* color: #333; */
}
.in-clicked {
  display: inline-block;
  width: 45%;
  padding: .75rem 0;
  margin: 0;
  font-weight: bold;
  color:#f86901;
  
}

/* .tab-bu-clicked ~ hr{
    margin-left: 25%;
} */




hr.job-tracking {
  height: .25rem;
  width: 45%;
  margin: 0;
  background: #f86901;
  border: none;
  transition: .3s ease-in-out;
}